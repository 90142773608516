export enum FeatureFlag {
  // Permanent Flags
  // ---------------------------------------------------------------------------
  PROMO_CODES_GUEST = 'web_fc_promo_codes_guest',
  PROMO_CODES_USER = 'web_fc_promo_codes',
  WEB_FC_BANNER = 'web_fc_banner',
  WEB_FC_ENABLE_EXPERIMENTS = 'web_fc_enable_experiments',
  WEB_FC_ENABLE_PAYPAL = 'web_fc_enable_paypal',
  WEB_FC_ENABLE_KLARNA = 'web_fc_enable_klarna',
  WEB_FC_ENABLE_GOOGLE_RECAPTCHA = 'web_fc_enable_google_recaptcha',
  WEB_FC_ENABLE_PRECHECK_EMAIL_SIGNUP = 'web_fc_enable_precheck_email_signup',
  FC_US_SEPARATE_OPS_COSTS = 'fc_us_separate_ops_costs',

  // Temporary Flags
  // ---------------------------------------------------------------------------
  CREDIT_CARD_VERIFICATION = 'web_fc_credit_card_verification',
  FORCE_HIDE_ONETRUST = 'temp_web_fc_force_hide_one_trust',
  GUEST_CHECKOUT = 'temp_web_fc_guest_checkout',
  HIDE_HIGH_PRICED_DEFECT_PRODUCT = 'temp_web_fc_hide_high_priced_defect_product',
  HIDE_HIGH_PRICED_USED_PRODUCT = 'temp_web_fc_hide_high_priced_used_product',
  IMPACT_TRACKING = 'temp_web_fc_impact_tracking',
  IVAR_FONT = 'temp_web_fc_ivar_font',
  LOCALIZATION = 'temp_web_fc_localization',
  MARKET_PRICING = 'temp_web_fc_market_pricing',
  ONE_TRUST = 'web_fc_one_trust',
  SHIPPING_DELAY = 'temp_web_fc_shipping_delay',
  STORYTELLING_GLOBAL = 'temp_web_fc_storytelling_global',
  STORYTELLING_PHASE1 = 'temp_web_fc_storytelling_phase_one',
  STRIPE_BILLING_ADDRESS_VALIDATION = 'temp_web_fc_stripe_address_validation',
  TEMP_WEB_FC_AFFIRM_WITH_LOCALE = 'temp_web_fc_affirm_with_locale',
  TEMP_WEB_FC_ALIAS_SELLING_PAGE = 'temp_web_fc_alias_selling_page',
  TEMP_WEB_FC_CHECKOUT_RTK = 'temp_web_fc_checkout_rtk',
  TEMP_WEB_FC_ENABLE_CONSTRUCTOR_BEACON = 'temp_web_fc_enable_constructor_beacon',
  TEMP_WEB_FC_FASTEST_TO_YOU_SHIPPING_OPTIONS = 'temp_web_fc_fastest_to_you_shipping_options',
  TEMP_WEB_FC_FLIGHT_CLUB_FRIDAYS = 'temp_web_fc_flight_club_fridays',
  TEMP_WEB_FC_HOLIDAY_SHIPPING_2022_NEXT_DAY = 'temp_web_fc_holiday_shipping_2022_next_day',
  TEMP_WEB_FC_HOLIDAY_SHIPPING_2022_STANDARD = 'temp_web_fc_holiday_shipping_2022_standard',
  TEMP_WEB_FC_HOME_FEED_VARIANT = 'temp_web_fc_home_feed_variant',
  TEMP_WEB_FC_PDP_GLOBAL_PRICING_COPY = 'temp_web_fc_pdp_global_pricing_copy',
  TEMP_WEB_FC_PAYPAL_TWO = 'temp_web_fc_paypal_two',
  WEB_FC_CHECKOUT_MARKET_PRICING = 'web_fc_checkout_market_pricing',
  WEB_FC_ORDER_CANCELLATION = 'web_fc_order_cancellation',
  TEMP_WEB_FC_HREFLANG = 'temp_web_fc_hreflang',
  TEMP_WEB_FC_ENABLE_EXPRESS_AFFIRM = 'temp_web_fc_enable_express_affirm',
  TEMP_WEB_FC_ENABLE_EXPRESS_AFTERPAY = 'temp_web_fc_enable_express_afterpay',
  TEMP_WEB_FC_ENABLE_EXPRESS_KLARNA = 'temp_web_fc_enable_express_klarna',
  TEMP_WEB_FC_ENABLE_FAVORITES = 'temp_web_fc_enable_favorites',
  TEMP_WEB_FC_ENABLE_FULLY_LANDED_PRICING_COPY_EXPERIMENT = 'temp_web_fc_enable_fully_landed_pricing_copy_experiment',
  TEMP_WEB_FC_ENABLE_MORE_ORDER_INFO = 'temp_web_fc_enable_more_order_info',
  TEMP_WEB_FC_ENABLE_NEW_COLLECTION_LINK_STYLE = 'temp_web_fc_enable_new_collection_link_style',
  TEMP_WEB_FC_ENABLE_OFFERS = 'temp_web_fc_enable_offers',
  TEMP_WEB_FC_ENABLE_PAYMENT_AVAILABILITY_SERVICE = 'temp_web_fc_enable_payment_availability_service',
  TEMP_WEB_FC_ENABLE_FC_STRIPE_API_KEY = 'temp_web_fc_enable_fc_stripe_api_key',
  TEMP_WEB_FC_ENABLE_THUMB_GALLERY = 'temp_web_fc_enable_thumb_gallery',
  TEMP_WEB_FC_ENABLE_CREATE_ACCOUNT_GUEST_CHECKOUT = 'temp_web_fc_enable_create_account_guest_checkout',
  TEMP_WEB_FC_ENABLE_STORE_PAGE_REDESIGNED = 'temp_web_fc_enable_store_page_redesigned',
  TEMP_WEB_FC_ENABLE_MY_ACCOUNT_REDESIGNED = 'temp_web_fc_enable_my_account_redesigned',
  TEMP_WEB_FC_EXPOSED_PAYMENT_METHODS = 'temp_web_fc_exposed_payment_methods',
  TEMP_WEB_FC_PDP_HIDE_FASTEST_TO_YOU = 'temp_web_fc_pdp_hide_fastest_to_you',
  TEMP_WEB_FC_REMOVE_CREDIT_CARD_CHECKOUT = 'temp_web_fc_remove_credit_card_checkout',
  TEMP_WEB_FC_FLIGHT_CLUB_INVITATIONAL_NAV = 'temp_web_fc_flight_club_invitational_nav',
  TEMP_WEB_FC_HOLIDAY_SHIPPING_COPY_UPDATE_2024 = 'temp_web_fc_holiday_shipping_copy_update_2024',
}

/**  An iterable form of the featureFlag enum values */
export const featureFlagsNames = Object.values(FeatureFlag)
