import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { FavoritesSlideOutPanel } from 'favorites/FavoritesSlideOutPanel'
import { LoginInstructions } from 'favorites/LoginInstructions'
import { LoginPanelModal } from 'favorites/LoginPanelModal'
import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import NavLink from 'layout/navigation/components/NavLink'
import { t } from 'localization'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { OffersModal } from 'offers/OffersModal'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { useUser } from 'shared/hooks/useUser'
import { sizes } from 'shared/lib/media'
import { setRenderPortal, setRenderSlideOut } from 'store/favoritesSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setIsOffersModalOpen, setLoginPromptStatus } from 'store/offersSlice'

export const FavoritesNavLink = ({
  closeNav,
  navTheme = 'desktop',
  id,
}: {
  closeNav?: () => void
  navTheme?: string
  id: string
}) => {
  const TP = 'favorites.components.FavoritesNavLink'
  const isOffersEnabled = useFeatureFlag(FeatureFlag.TEMP_WEB_FC_ENABLE_OFFERS)
  const [currentTab, setCurrentTab] = useState<'favorites' | 'offers'>('favorites')
  const [isLoginPanelModalOpen, setIsLoginPanelModalOpen] = useState(false)
  const dispatch = useAppDispatch()
  const { isAuthenticated } = useUser()
  const { loggedInToViewFavorites, renderPortal } = useAppSelector((state) => state.favorites)
  const { loggedInToViewOffers, myAccountViewOffersShortcut, promptLogin, isOffersModalOpen } =
    useAppSelector((state) => state.offers)

  const handleOpenSlider = () => {
    if (closeNav) {
      closeNav()
    }
    setCurrentTab('favorites')
    dispatch(setRenderPortal(true))
    dispatch(setRenderSlideOut(true))
    sendTrackingEvent('HEADER_NAVIGATION_LINK_TAP', { section_name: 'Favorites' })
  }

  const handleCloseLoginPanelModal = () => {
    setIsLoginPanelModalOpen(false)
  }

  const handleOpenLoginPanelModal = () => {
    setIsLoginPanelModalOpen(true)
  }

  const handleCloseOffersModal = () => {
    dispatch(setIsOffersModalOpen({ status: false }))
  }

  const handleCloseOffersLoginPrompt = () => {
    dispatch(setLoginPromptStatus(false))
    dispatch(setIsOffersModalOpen({ status: false }))
  }

  useEffect(() => {
    // User can view their favorites and offers after successful log in
    if (isAuthenticated && (loggedInToViewFavorites || loggedInToViewOffers)) {
      const targetTab = isOffersEnabled && loggedInToViewOffers ? 'offers' : 'favorites'
      dispatch(setRenderPortal(true))
      dispatch(setRenderSlideOut(true))
      setIsLoginPanelModalOpen(false)
      setCurrentTab(targetTab)
    }
  }, [isAuthenticated, loggedInToViewFavorites, loggedInToViewOffers, isOffersEnabled])

  useEffect(() => {
    // Opens offers tab when user clicks "My Offers" in the Account Menu
    if (isOffersEnabled && myAccountViewOffersShortcut) {
      dispatch(setRenderPortal(true))
      dispatch(setRenderSlideOut(true))
      setCurrentTab('offers')
    }
  }, [isAuthenticated, myAccountViewOffersShortcut, isOffersEnabled])

  return (
    <>
      {(navTheme === 'mobile' || navTheme === 'desktop') && (
        <StyledNavLink data-qa={`FavoritesNavLink${id}`} onClick={handleOpenSlider} tabIndex={0}>
          {t(`${TP}.navLinkText`, 'Favorites')}
        </StyledNavLink>
      )}

      {isLoginPanelModalOpen && (
        <LoginPanelModal
          handleCloseLoginPanelModal={handleCloseLoginPanelModal}
          location={currentTab === 'favorites' ? 'global_favorite_panel' : 'global_offer_panel'}
          flow={currentTab === 'favorites' ? 'viewFavorites' : 'viewOffers'}
        />
      )}
      {!isAuthenticated && renderPortal && (
        <LoginInstructions
          data-qa="LoginInstructions"
          handleOpenLoginPanelModal={handleOpenLoginPanelModal}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      )}
      {isAuthenticated && renderPortal && (
        <FavoritesSlideOutPanel currentTab={currentTab} setCurrentTab={setCurrentTab} />
      )}
      {isOffersEnabled && isOffersModalOpen && (
        <OffersModal
          handleCloseOffersModal={handleCloseOffersModal}
          handleCloseOffersLoginPrompt={handleCloseOffersLoginPrompt}
          promptLogin={promptLogin}
        />
      )}
    </>
  )
}

const StyledNavLink = styled(NavLink)`
  @media (max-width: ${sizes.large / 16}em) {
    color: #333333;
    font-weight: 400;
    padding: 12px 0px;
  }

  &:focus-visible {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }
`
