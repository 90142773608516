import { Field } from 'formik'
import styled from 'styled-components'

import CircleCheckbox from 'shared/components/Checkbox/CircleCheckbox'
import HiddenCheckbox from 'shared/components/Checkbox/HiddenCheckbox'
import Label from 'shared/components/Checkbox/Label'
import { colors } from 'shared/lib'

type FormikCheckboxProps = {
  checked: boolean
  className?: string
  isDarkMode?: boolean
  isStandardCheckbox?: boolean
  label: string
  name: string
  noMargin?: boolean
  text: string
}

const FormikCheckbox = ({
  checked,
  className,
  isDarkMode = false,
  isStandardCheckbox = false,
  label,
  name,
  noMargin,
  text,
}: FormikCheckboxProps) => {
  return isStandardCheckbox ? (
    <div className={className}>
      <CustomLabel>
        <Checkbox name={name} type="checkbox" id={label} checked={checked} />
        {text}
      </CustomLabel>
    </div>
  ) : (
    <div className={className}>
      <Field name={name}>
        {({ field }) => (
          <>
            {isDarkMode ? (
              <CircleCheckbox {...field} type="checkbox" id={label} checked={checked} />
            ) : (
              <HiddenCheckbox {...field} type="checkbox" id={label} checked={checked} />
            )}
            <Label htmlFor={label} noMargin={noMargin}>
              {text}
            </Label>
          </>
        )}
      </Field>
    </div>
  )
}

const Checkbox = styled(Field)`
  accent-color: ${colors.FC2_BLACK};
  height: 12px;
  margin-right: 15px;
  width: 12px;
`
const CustomLabel = styled.label`
  align-items: center;
  display: flex;
  justify-content: center;
`

export default FormikCheckbox
