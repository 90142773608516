import styled from 'styled-components'

import Bugsnag, { NotifiableError } from '@bugsnag/js'
import { useLazyGetProductTemplateQuery } from 'api/productTemplateApi'
import { t } from 'localization'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { OfferModalDetails, OffersListResponse } from 'offers/types'
import { getRemainingOfferTime } from 'offers/utils'
import Button from 'shared/components/Button'
import { colors, fonts } from 'shared/lib'
import { formatMoneyObject } from 'shared/lib/formatMoneyObject'
import { sizes } from 'shared/lib/media'
import { PTItemOffer, PTListsItem, PTListsProductTemplate } from 'shared/types/ProductTemplateLists'
import { useAppDispatch } from 'store/hooks'
import { setIsOffersModalOpen, setOfferToBeRemoved } from 'store/offersSlice'

type OfferSizesProps = {
  primaryIndex: number
  ptOffers: OffersListResponse
}

export const OfferSizes = ({ ptOffers, primaryIndex }: OfferSizesProps) => {
  const TP = 'offers.components.OfferSizes'
  const { productTemplate: PT, items } = ptOffers
  const dispatch = useAppDispatch()
  const [getProductTemplate] = useLazyGetProductTemplateQuery()

  const handleDeleteClick = (PT: PTListsProductTemplate, item: PTListsItem<PTItemOffer>) => {
    const wantId = Number(item.profileItemData.offer.id)
    sendTrackingEvent('OFFER_REMOVE_TAP', {
      product_template_slug: PT.slug,
      size: item.size.presentationValue,
      want_id: wantId,
      location: 'global_favorite_panel',
    })

    dispatch(setOfferToBeRemoved({ wantId, slug: PT.slug, size: item.size.presentationValue }))
  }

  const handleEditOfferClick = (
    PT: OffersListResponse['productTemplate'],
    item: OffersListResponse['items'][0],
  ) => {
    const offerModalDetails: OfferModalDetails = {
      lowestPriceCents: {
        display: formatMoneyObject(item.lowestPriceCents),
        value: item.lowestPriceCents.amount!,
      },
      minimumOfferCents: PT.minimumOfferCents, // this is fetched later if not available
      name: PT.name,
      offer: item.profileItemData.offer,
      operation: 'edit',
      openFrom: 'global_offer_panel',
      pictureUrl: PT.pictureUrl,
      productTemplateId: `${PT.id}`,
      size: {
        display: item.size.presentationValue,
        value: item.size.size,
      },
      sizeCategory: PT.gender,
      slug: PT.slug,
    }

    // display modal immediately with available info
    dispatch(setIsOffersModalOpen({ offerModalDetails, status: true }))

    // fetch product template for missing minimum offer cents
    // and re-render modal with updated info
    if (!PT.minimumOfferCents) {
      getProductTemplate(PT.slug, true)
        .unwrap()
        .then((response) => {
          dispatch(
            setIsOffersModalOpen({
              offerModalDetails: {
                ...offerModalDetails,
                minimumOfferCents: response.minimumOfferCents,
              },
              status: true,
            }),
          )
        })
        .catch((e) => {
          Bugsnag.notify(e as NotifiableError)
        })
    }

    // send tracking event
    if (offerModalDetails.offer) {
      sendTrackingEvent('OFFER_EDIT_TAP', {
        product_template_slug: offerModalDetails.slug,
        size: offerModalDetails.size?.display || '',
        offer_duration: offerModalDetails.offer.offerDuration,
        offer_amount: `${(offerModalDetails.offer.offerAmount.amountUsdCents || 0) / 100}`,
        pref_currency_offer_amount: `${(offerModalDetails.offer.offerAmount.amount || 0) / 100}`,
        pref_currency: offerModalDetails.offer.offerAmount.currency,
        buy_now: offerModalDetails.lowestPriceCents!.value,
        want_id: offerModalDetails.offer.id,
        location: 'global_offer_panel',
      })
    }
  }

  return (
    <OfferSizesWrapper>
      {items.map((item) => {
        const sizeLabel = `${PT.sizeUnit} ${PT.abbreviatedGender} ${item?.size?.presentationValue}`
        const { offerAmount } = item.profileItemData.offer
        const {
          display: remainingTime,
          isLessThanADay,
          expired,
        } = getRemainingOfferTime(item.profileItemData.offer)

        return (
          <OfferSingleSize key={`${PT.id}-${item.size.size}`}>
            <OfferProductSize>
              <div data-qa={`OffersListPtSize${primaryIndex}`}>{sizeLabel}</div>
              <OfferDeleteCTA
                data-qa={`OffersListDeleteButton${primaryIndex}`}
                onClick={() => handleDeleteClick(PT, item)}
              >
                {t(`${TP}.deleteCta`, 'Delete')}
              </OfferDeleteCTA>
            </OfferProductSize>
            <OfferButtons>
              <Button
                buttonType="primary1"
                $fill
                onClick={() => handleEditOfferClick(PT, item)}
                data-qa={`OffersListEditOfferButton${primaryIndex}`}
              >
                {t(`${TP}.editOffer`, 'Edit Offer - {offerPrice}', {
                  offerPrice: ` ${formatMoneyObject(offerAmount)}`,
                })}
              </Button>
            </OfferButtons>
            <OfferExpiration
              data-qa={`FavoritesOverlayOffersExpirationText${primaryIndex}`}
              className="offer-expiration"
              $urgent={isLessThanADay || expired}
            >
              {t(`${TP}.expires`, 'Offer expires ')}
              <span>{remainingTime}</span>
            </OfferExpiration>
          </OfferSingleSize>
        )
      })}
    </OfferSizesWrapper>
  )
}

const OfferButtons = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding-bottom: 20px;
  gap: 20px;
  > button {
    height: 40px;
    line-height: 30px;
    text-transform: uppercase;
    @media (max-width: ${sizes.large / 16}em) {
      width: 100%;
    }
  }
`

const OfferDeleteCTA = styled.button`
  font-weight: 400;
  font-size: 11px;
  color: ${colors.FC2_GREY};
  cursor: pointer;
`

const OfferExpiration = styled.div<{ $urgent: boolean }>`
  font-weight: 400;
  font-size: 12px;
  color: ${({ $urgent }) => ($urgent ? colors.FC2_RED : colors.FC2_BLACK)};
  padding-bottom: 20px;

  span {
    font-weight: 500;
  }
`

const OfferProductSize = styled.div`
  display: flex;
  justify-content: space-between;
  ${fonts.SUBTITLE_3};
  margin: 15px 0px;
`

const OfferSingleSize = styled.div`
  margin: 0px 20px;
`

const OfferSizesWrapper = styled.div`
  & div:not(:last-child) {
    .offer-expiration {
      border-bottom: 1px solid ${colors.FC2_LIGHTEST_GREY};
    }
  }
`
