import { useRouter } from 'next/router'
import { Dispatch, SetStateAction, useCallback, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'

import { NavThemeNames } from 'layout/navigation/Navigation'
import { getLocalePath, t } from 'localization'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { colors, mediaQueries } from 'shared/theme'
import NavLink from '../components/NavLink'
import SearchInput from './SearchInput'

const TP = 'layout.navigation.search.search'

interface SearchProps {
  setNavTheme: Dispatch<SetStateAction<NavThemeNames>>
  navTheme: NavThemeNames
  getUnopenedNavTheme: () => NavThemeNames
  searchText: string
  setSearchText: Dispatch<SetStateAction<string>>
  sneakersCancellableHoverTimeout?: number
}

const Search = ({
  searchText,
  setNavTheme,
  navTheme,
  setSearchText,
  getUnopenedNavTheme,
  sneakersCancellableHoverTimeout,
}: SearchProps) => {
  const searchInputEl = useRef<HTMLInputElement>(null)
  const router = useRouter()

  const handleKeyPress = useCallback(
    ({ keyCode }) => {
      // ESC
      if (keyCode === 27 && navTheme === 'search') {
        closeSearch()
      }
      // ENTER
      if (keyCode === 13 && navTheme === 'search') {
        // would be ideal to route client side, but SearchPage + Grid
        // are too buggy to allow it at the moment. Needs updating!
        sendTrackingEvent('SEARCH_BAR_SEARCH_TAP', {
          page: window?.location?.href,
          search_string: searchText,
        })
        window.location.href = getLocalePath(
          `/catalogsearch/result?query=${searchText}`,
          router.locale,
        )
      }
    },
    [searchText, navTheme],
  )
  useEffect(() => {
    window.addEventListener('keyup', handleKeyPress)
    return () => {
      window.removeEventListener('keyup', handleKeyPress)
    }
  }, [handleKeyPress])

  const resetSearchText = () => {
    setSearchText('')
  }
  useEffect(() => {
    router.events.on('routeChangeComplete', resetSearchText)

    return () => {
      router.events.off('routeChangeComplete', resetSearchText)
    }
  }, [])

  const openSearch = () => setNavTheme('search')

  const clearSearch = () => {
    setSearchText('')
    searchInputEl.current?.focus()
  }
  const closeSearch = () => {
    setSearchText('')
    setNavTheme(getUnopenedNavTheme())
    searchInputEl.current?.blur()
  }

  const handleSearchHover = () => {
    if (sneakersCancellableHoverTimeout) {
      window.clearTimeout(sneakersCancellableHoverTimeout)
    }
  }

  return (
    <ExpandableContainer onMouseEnter={handleSearchHover}>
      <SearchInput
        openSearch={openSearch}
        searchInputEl={searchInputEl}
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <SearchButtons>
        <ClearBtn
          data-qa="NavigationSearchClearBtn"
          onClick={clearSearch}
          disabled={!searchText.length}
          tabIndex={0}
        >
          {t(`${TP}.clear`, 'Clear')}
        </ClearBtn>
        <SearchButtonDivider />
        <CloseBtn
          data-qa="NavigationSearchCloseBtn"
          onClick={closeSearch}
          tabIndex={navTheme === 'search' ? 0 : -1}
        >
          {t(`${TP}.close`, 'Close')}
        </CloseBtn>
      </SearchButtons>
    </ExpandableContainer>
  )
}

const ExpandableContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding-left: 25px;
  pointer-events: none;
  opacity: 1;
  transition: opacity 200ms ease-in 300ms;
  ${(props) =>
    props.theme.hideSearch &&
    css`
      pointer-events: none;
      transition: opacity 200ms;
      opacity: 0;
    `}
  ${mediaQueries.smallNavigation} {
    padding-left: 20px;
  }
  ${(props) =>
    props.theme.menuOpen &&
    css`
      pointer-events: auto;
    `}
`

const ClearBtn = styled(NavLink)`
  color: ${colors.darkGray};
  white-space: nowrap;
  &:disabled {
    color: ${colors.mediumGray};
    cursor: auto;
  }
  &:focus {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }
`

const CloseBtn = styled(NavLink)`
  white-space: nowrap;
  &:focus {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }
`

const SearchButtons = styled.div`
  display: flex;
  pointer-events: none;
  transition: opacity 200ms;
  opacity: 0;
  button {
    padding: 12px 24px;
    ${mediaQueries.smallNavigation} {
      font-weight: 400;
    }
  }
  ${(props) =>
    props.theme.menuOpen &&
    css`
      pointer-events: auto;
      transition: opacity 200ms 200ms;
      opacity: 1;
    `}
`

const SearchButtonDivider = styled.div`
  height: 76px;
  width: 2px;
  background-color: ${(props) => props.theme.borderColor};
`

export default Search
